import { pluck } from 'rxjs/operators';
import { RecipientsHttpClient } from "../../http_clients/RecipientsHttpClient";
import { RecipientModel } from "components/models/RecipientModel";
import { RecipientsSearchModel } from "components/models/RecipientsSearchModel";
import { UsersHttpClients } from "../../http_clients/UsersHttpClients";
import { Router, RouterEvent, NavigationInstruction, PipelineResult, RouterConfiguration } from "aurelia-router";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { FriendRequestModel } from "components/models/FriendsRequestModel";
import { FriendModel } from "components/models/FriendModel";
import { User } from "components/models/UserModel";
import { AuthService } from "aurelia-auth";
import { json } from "aurelia-fetch-client";
import { SingletonService } from "singleton";
import { I18N } from "aurelia-i18n";
import { DialogController, DialogService } from "aurelia-dialog";
import { MenuItem } from "components/models/MenuItem";
import { EventAggregator } from "aurelia-event-aggregator";
import { connectTo, Store } from "aurelia-store";
import { State } from "state";
import { Accept } from "pages/contacts/accept/accept";
import { IGenericAccount } from 'components/models/YapilyModels/IGenericAccount';
import { IGenericContact } from 'components/models/IGenericContact';
import { Add } from 'pages/contacts/add/add';
import { inject, autoinject, computedFrom } from "aurelia-framework";
import * as $ from "jquery";

@connectTo({
  selector: {
    allContacts: (store) => store.state.pipe(pluck('contacts')),
    me: (store) => store.state.pipe(pluck('me')),
    selectedContactForDetails: (store) => store.state.pipe(pluck('selectedContactForDetails')),
    wallets: (store) => store.state.pipe(pluck('wallets')),
    makePayment: (store) => store.state.pipe(pluck('makePayment')),
  }
})
@autoinject
export class Contacts {
  private search_query: string = "";
  private me: User;
  private tabs: number = 0;
  private listMenuItems: Array<MenuItem> = [];
  private currentPage: string = "mine";
  private pageTitle: string = "";
  private isEditing: boolean = false;
  private isDropDown: boolean = false;
  private subtitle: string = "";
  private selectedContactForDetails;
  private myContacts: IGenericContact[];
  private mySentFriendsRequests: IGenericContact[];
  private myReceivedFriendsRequests: IGenericContact[];
  private myBeneficiaryList;
  private isContactsPage = true;
  private navEvent;
  private currentInstruction;
  private search_term: string = "";
  private isExtended: boolean = false;
  wallets: any;
  //private placeholderText: string = this.i18n.tr("contacts.recherche_add");

  constructor(
    private router: Router,
    private i18n: I18N,
    private dialogService: DialogService,
    private store: Store<State>,
    private ea: EventAggregator,
    private authService: AuthService,
    private recipientsHttpClient: RecipientsHttpClient,
    private dialogController: DialogController,
    private state: State
  ) {
    this.ea.subscribe(
      RouterEvent.Complete,
      (event: {
        instruction: NavigationInstruction;
        result: PipelineResult;
      }) => {
        this.isContactsPage =
        event.instruction.fragment === "/contacts/mine" ||
        event.instruction.fragment === "/contacts/received" ||
        event.instruction.fragment === "/contacts/sent" ||
        event.instruction.fragment === "/contacts/beneficiary" ? true : false;
      }
    );
  }

  activate(params) {
    this.currentPage = params.page;
    this.setPageTitle();
  }

  attached(): void {
    this.navEvent = this.ea.subscribe(RouterEvent.Success, (event: {
      instruction: NavigationInstruction;
      result: PipelineResult;
    }) => {
      this.currentInstruction = event.instruction;
    });

    this.ea.subscribe("router:navigation:success", () => {
      this.getMe();
    });
  }

  async getMe() {
    this.me = await this.authService.getMe();
  }

  deactivate() {
  }

  async filterContacts(allContacts) {
    await this.getMe();
    if (allContacts && allContacts.length >= 0) {
      // filter and update this.contacts
      this.myContacts = allContacts.filter(
        (contact) => contact.status === "accepted"
      );
      this.mySentFriendsRequests = allContacts.filter(
        (contact) =>
          contact.status === "requested" &&
          contact.requester != this.me._id
      );
      this.myReceivedFriendsRequests = allContacts.filter(
        (contact) =>
          contact.status === "requested" &&
          contact.requester === this.me._id
      );
    }
  }

  async fillMenuItems(newContacts) {
    await this.filterContacts(newContacts);
    this.listMenuItems = [];
    const contactMenuItem = new MenuItem(
      this.i18n.tr("contacts.term"),
      "mine",
      this.myContacts.length
    );
    const receivedRequestsMenuItem = new MenuItem(
      this.i18n.tr("contacts.received"),
      "received",
      this.mySentFriendsRequests.length
    );
    const sentRequestsMenuItem = new MenuItem(
      this.i18n.tr("contacts.sent"),
      "sent",
      this.myReceivedFriendsRequests.length
    );

    this.listMenuItems.push(contactMenuItem);
    this.listMenuItems.push(receivedRequestsMenuItem);
    this.listMenuItems.push(sentRequestsMenuItem);
  }

  setPageTitle() {
    switch (this.currentPage) {
      case "mine":
        this.tabs = 0;
        this.pageTitle = this.i18n.tr("contacts.contacts_emoji");
        this.subtitle = this.i18n.tr("contacts.subtitle.mine")
        break;
      case "received":
        this.tabs = 1;
        this.pageTitle = this.i18n.tr("contacts.invite_received");
        this.subtitle = this.i18n.tr("contacts.subtitle.received")
        break;
      case "sent":
        this.tabs = 2;
        this.pageTitle = this.i18n.tr("contacts.invite_sent");
        this.subtitle = this.i18n.tr("contacts.subtitle.sent")
        break;
    }
  }

  async allContactsChanged(newContacts) {
    await this.fillMenuItems(newContacts);
  }

  acceptFriendRequest(contact: IGenericContact) {
    if (contact.email && contact.email != undefined) {

      this.store.dispatch("contacts.adding.accept.selected", contact);
      this.dialogService.open({ viewModel: Accept, lock: false }).whenClosed(result => {
        if(result) {
          this.store.dispatch("contacts.adding.search.selected", contact);
          this.router.navigateToRoute("contacts", { page: "mine" })
        }
      })
    }
    else {
    }
  }

  deleteFriendRequest(contact: IGenericContact) {
    if (contact.email && contact.email != undefined) {
      let confirmation = confirm("Are you sure to delete this contact?");
      if (confirmation) {
        this.ea.publish("contact.remove.one.backend", contact);
        return true;
      } else {
        return false;
      }
    } else {
      let confirmation = confirm("Are you sure to delete this contact?");
      if (confirmation) {
        this.ea.publish("recipient.remove.one.backend", contact.id);
        return true;
      } else {
        return false;
      }      
    }
  }

  async goToContactDetails(contact: IGenericContact) {
    if (contact.accounts && !this.isEditing) {

      const previouslySelectedContact = this.selectedContactForDetails;
      await this.store.dispatch("contacts.adding.details", contact);
    
      if (contact.accounts.length > 1) {
        if (previouslySelectedContact && previouslySelectedContact.id === contact.id && this.isExtended) {
          this.isExtended = false;
        } else {
          this.isExtended = true;
          this.selectedContactForDetails = contact;
        }
      } else if (contact.accounts.length < 2) {
        this.store.dispatch("set.make_payment.mk", false);
        this.store.dispatch('set.make_payment.recipient', contact.accounts[0]);
        this.router.navigateToRoute("payments");
      } else {
        
      }
    } else if (!contact.accounts && !this.isEditing) {
      contact.accounts = this.wallets;
      const previouslySelectedContact = this.selectedContactForDetails;
      await this.store.dispatch("contacts.adding.details", contact);
      if (contact.accounts.length > 1) {
        if (previouslySelectedContact && previouslySelectedContact.id === contact.id && this.isExtended) {
          this.isExtended = false;
        } else {
          this.isExtended = true;
          this.selectedContactForDetails = contact;
        }
      } else if (contact.accounts.length < 2) {
        this.store.dispatch("set.make_payment.mk", false);
        this.store.dispatch('set.make_payment.recipient', contact.accounts[0]);
        this.router.navigateToRoute("payments");
      } else {
        
      }
    } else {

    }
  }

  selectAccount(account: IGenericAccount) {
    this.store.dispatch("set.make_payment.mk", false);
    this.store.dispatch('set.make_payment.recipient', account);
    this.router.navigateToRoute("payments");
  }


  startPaymentTo(account: IGenericAccount){
    this.store.dispatch('set.make_payment.recipient', account)
    this.router.navigateToRoute("payments");
  }

  setPaymentRecipient(wallet: IGenericAccount) {
    this.store.dispatch("set.make_payment.mk", false);
    this.store.dispatch('set.make_payment.recipient', wallet);
    this.router.navigateToRoute("payments");
  }

  // SEARCH

  @computedFrom("myContacts", "search_term")
  get search_results_contacts(): Array<any> {
    if (!this.myContacts) {
        return [];
    }

    // Filtrer les contacts selon le terme de recherche
    let filteredContacts = this.myContacts.map((contact: IGenericContact) => {
        let filteredAccounts = contact.accounts.filter(account =>
            account.identification.toString().toLowerCase().indexOf(this.search_term.toString().toLowerCase()) > -1
        );

        let isContactMatched = 
            contact.name.toString().toLowerCase().indexOf(this.search_term.toString().toLowerCase()) > -1 ||
            (contact.email == undefined ? "" : contact.email).toString().toLowerCase().indexOf(this.search_term.toString().toLowerCase()) > -1;

        if (isContactMatched) {
            return contact;
        }

        if (filteredAccounts.length > 0) {
            return {
                ...contact,
                accounts: filteredAccounts
            };
        }

        return null;
    }).filter(contact => contact !== null);

    // Regrouper les contacts par lettre alphabétique
    let groupedContacts: Array<any> = [];
    let currentLetter: string | null = null;

    filteredContacts
        .sort((a, b) => a.name.localeCompare(b.name)) // Tri alphabétique
        .forEach(contact => {
            let firstLetter = contact.name.charAt(0).toUpperCase();

            // Ajouter une entrée pour la lettre si c'est une nouvelle lettre
            if (currentLetter !== firstLetter) {
                groupedContacts.push({ isGroupLabel: true, label: firstLetter });
                currentLetter = firstLetter;
            }

            // Ajouter le contact à la suite de la lettre
            groupedContacts.push({ isGroupLabel: false, contact });
        });

    return groupedContacts;
  }

  goToHome() {
    this.router.navigateToRoute("home");
  }

  goToAdd() {
    this.dialogService.open({ viewModel: Add, lock: false })
  }

  goToProfile() {
    this.router.navigateToRoute("profile");
  }

  goToNotfications() {
    this.router.navigateToRoute("notifications");
  }
}
